<template>
  <div id="OrderManagement">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'OrderManagement',
  };
</script>

<style lang="scss" scoped>
  #OrderManagement {
    height: 100%;
  }
</style>
